import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { BASE_URL } from "../../../api/core";
import Logo from "../../../assets/logo_home.png"; // Replace with your logo path
import UploadImg from "../../../assets/image.png"; // Replace with your upload icon path
import "./AddAvatar.css"; // Ensure to import CSS
import { message } from "antd";

const AddAvatar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const userId = location.state?.userId;

  const [avatar, setAvatar] = useState(null);
  const [avatarURL, setAvatarURL] = useState("");
  const [errors, setErrors] = useState({});

  const [messageApi, contextHolder] = message.useMessage();

  const errorMsg = (msg) => {
    messageApi.open({
      type: "error",
      content: msg,
    });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setAvatar(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setAvatarURL(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const validate = () => {
    let errors = {};
    if (!avatar) {
      errors.avatar = "Avatar is required";
    }
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleImageUpload = async (file) => {
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    const hideLoading = message.loading("Uploading avatar...", 0);

    try {
      const data = new FormData();
      data.append("file", file);
      data.append("cloud_name", "dxgmi9qni");
      data.append("upload_preset", "hobbie_app");

      const response = await axios.post(
        "https://api.cloudinary.com/v1_1/dxgmi9qni/image/upload",
        data,
        config
      );

      return response.data.secure_url;
    } catch (error) {
      errorMsg("Failed to upload avatar. Please try again.");
      return null;
    } finally {
      hideLoading();
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      const url = await handleImageUpload(avatar);

      if (url) {
        const profileData = { image_url: url };
        const token = localStorage.getItem("token");
        if (!token) return;

        const hideLoading = message.loading("Saving avatar...", 0);

        try {
          await axios.put(`${BASE_URL}/add-avatar`, profileData, {
            headers: { Authorization: `Bearer ${token}` },
          });
          navigate("/select-hobbies");
        } catch (error) {
          errorMsg("Failed to save avatar. Please try again.");
        } finally {
          hideLoading();
        }
      }
    }
  };

  return (
    <div style={styles.container}>
      {contextHolder}
      {/* Left Section */}
      <div style={styles.leftSection}>
        <img src={Logo} alt="Hobbee Logo" style={styles.logo} />
        <h1 style={styles.title}>Hobbee</h1>
        <p style={styles.subtitle}>Pursue Your Passion</p>
      </div>

      {/* Right Section */}
      <div style={styles.rightSection}>
        <form onSubmit={handleSubmit} style={styles.form}>
          <h2 style={styles.formTitle}>Upload Your Avatar</h2>
          <div
            style={styles.uploadContainer}
            onClick={() => document.getElementById("file-upload").click()}
          >
            {avatarURL ? (
              <img
                src={avatarURL}
                alt="Avatar Preview"
                style={styles.avatarPreview}
              />
            ) : (
              <>
                <img src={UploadImg} alt="Upload Icon" style={styles.uploadIcon} />
                <p style={styles.uploadText}>Click to upload an avatar</p>
              </>
            )}
            <input
              type="file"
              id="file-upload"
              name="avatar"
              onChange={handleFileChange}
              style={{ display: "none" }}
            />
          </div>
          {errors.avatar && <span style={styles.error}>{errors.avatar}</span>}
          <button type="submit" style={styles.button}>
            Save and Continue
          </button>
        </form>
        <p style={styles.skipText}>
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              navigate("/select-hobbies");
            }}
            style={styles.link}
          >
            Skip for now
          </a>
        </p>
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    flexDirection: "row",
    height: "100vh",
    fontFamily: "Arial, sans-serif",
  },
  leftSection: {
    flex: 1,
    backgroundColor: "#FFD700", // Yellow background
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "20px",
  },
  logo: {
    width: "80px",
    marginBottom: "10px",
  },
  title: {
    fontSize: "28px",
    fontWeight: "bold",
    marginBottom: "10px",
  },
  subtitle: {
    fontSize: "16px",
    textAlign: "center",
  },
  rightSection: {
    flex: 1,
    backgroundColor: "#FFFFFF",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "20px",
  },
  form: {
    width: "80%",
    maxWidth: "400px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  formTitle: {
    fontSize: "24px",
    fontWeight: "bold",
    marginBottom: "20px",
    textAlign: "center",
  },
  uploadContainer: {
    width: "200px",
    height: "200px",
    border: "2px dashed #ccc",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    marginBottom: "15px",
    flexDirection: "column",
  },
  avatarPreview: {
    width: "100%",
    height: "100%",
    borderRadius: "50%",
  },
  uploadIcon: {
    width: "50px",
    height: "50px",
  },
  uploadText: {
    fontSize: "14px",
    color: "#888888",
    textAlign: "center",
  },
  button: {
    padding: "10px",
    fontSize: "16px",
    backgroundColor: "#FFD700",
    color: "#000000",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
  },
  error: {
    fontSize: "12px",
    color: "red",
  },
  skipText: {
    marginTop: "20px",
    fontSize: "14px",
    textAlign: "center",
  },
  link: {
    color: "#b6a005",
    textDecoration: "none",
  },
};

export default AddAvatar;
