import React, { useState, useEffect } from "react";
import axios from "axios";
import { BASE_URL } from "../../../../../api/core";
import { message } from "antd";

const UserTable = () => {
  const [users, setUsers] = useState([]);
  const [filter, setFilter] = useState("7");
  const [messageApi, contextHolder] = message.useMessage();

  useEffect(() => {
    fetchUsers();
  }, [filter]);

  const fetchUsers = async () => {
    const token = localStorage.getItem("token");

    if (!token) return;

       const hideLoading = message.loading("Loading data, please wait..", 0);
    

    try {
      const response = await axios.get(
        `${BASE_URL}/stats/new-users?days=${filter}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setUsers(response.data);

      hideLoading();
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  return (
    <div style={{ padding: "20px", fontFamily: "Arial, sans-serif" }}>
      <h1>User Table</h1>
      <div style={{ marginBottom: "10px" }}>
        <button onClick={() => setFilter("1")} style={{ marginRight: "10px" }}>
          Last 1 Day
        </button>
        <button onClick={() => setFilter("7")} style={{ marginRight: "10px" }}>
          Last 7 Days
        </button>
        <button onClick={() => setFilter("30")} style={{ marginRight: "10px" }}>
          Last 30 Days
        </button>
      </div>
      <table style={{ width: "100%", borderCollapse: "collapse" }}>
        <thead>
          <tr style={{ backgroundColor: "#f4f4f4" }}>
            <th style={{ border: "1px solid #ddd", padding: "8px" }}>ID</th>
            <th style={{ border: "1px solid #ddd", padding: "8px" }}>
              profile
            </th>
            <th style={{ border: "1px solid #ddd", padding: "8px" }}>Name</th>
            <th style={{ border: "1px solid #ddd", padding: "8px" }}>Email</th>
            <th style={{ border: "1px solid #ddd", padding: "8px" }}>
              Location
            </th>
            <th style={{ border: "1px solid #ddd", padding: "8px" }}>
              Created At
            </th>
           
          </tr>
        </thead>
        <tbody>
          {users.length > 0 ? (
            users.map((user,index) => (
              <tr key={user.id}>
                <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                  {index + 1}
                </td>
                <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                < img src={user.image_url} alt="profile" style={{ width: "50px", height: "50px", borderRadius: "50%" }} />  
                </td>
                <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                  {user.firstname}  {user.lastname}
                </td>
                <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                  {user.email}
                </td>
                <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                {user.address} 
                </td>
                <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                  {user.created_at_time_ago}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="5" style={{ textAlign: "center", padding: "8px" }}>
                No users found
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default UserTable;
