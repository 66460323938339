import React, { useContext, useState } from "react";
import Logo from "../../../assets/logo_home.png"; // Replace with your logo path
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { BASE_URL } from "../../../api/core";
import { message } from "antd";
import { UserContext } from "../../../context";

const VerifyResetCode = () => {
  const navigate = useNavigate();
  const { email, setResetCode } = useContext(UserContext);

  const [verificationCode, setVerificationCode] = useState("");

  const [messageApi, contextHolder] = message.useMessage();

  const handleContinue = async (e) => {
    e.preventDefault(); // Prevent default form submission
    const token = localStorage.getItem("token"); // Use localStorage for React.js

    const hideLoading = message.loading("Submitting request...", 0);

    try {
      const response = await axios.post(
        `${BASE_URL}/verify/code`,
        {
          reset_code: verificationCode,
          email: email,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      hideLoading();
      setResetCode(verificationCode);

      window.alert("Success: Reset code is valid, click OK to continue.");
      navigate("/reset-password");
    } catch (error) {
      hideLoading();
      if (error.response) {
        const errorMessage =
          error.response.data.message || "An unknown error occurred.";
        window.alert(`Error: ${errorMessage}`);
      } else if (error.request) {
        window.alert(
          "Error: No response from the server. Please check your network connection."
        );
      } else {
        window.alert(`Request error: ${error.message}`);
      }
    }
  };

  return (
    <div style={styles.container}>
      {contextHolder}

      {/* Left Section */}
      <div style={styles.leftSection}>
        <img src={Logo} alt="Hobbee Logo" style={styles.logo} />
        <h1 style={styles.title}>Hobbee</h1>
        <p style={styles.subtitle}>Pursue Your Passion</p>
      </div>

      {/* Right Section */}
      <div style={styles.rightSection}>
        <form onSubmit={handleContinue} style={styles.form}>
          <h2 style={styles.formTitle}>Enter Verification Code</h2>
          <p style={styles.description}>
            Please enter the 6-digit verification code sent to your email.
          </p>
          <div style={styles.inputGroup}>
            <input
              type="text"
              placeholder="Enter 6-digit code"
              value={verificationCode}
              onChange={(e) => setVerificationCode(e.target.value)}
              style={styles.input}
              required
            />
          </div>
          <button type="submit" style={styles.button}>
            Verify Code
          </button>
        </form>
        <p style={styles.goBackText}>
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              navigate("/"); // Navigate back to login page
            }}
            style={styles.link}
          >
            &larr; Go Back To Login
          </a>
        </p>
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    flexDirection: "row",
    height: "100vh",
    fontFamily: "Arial, sans-serif",
  },
  leftSection: {
    flex: 0.3, // 30% of the width
    backgroundColor: "#FFD700", // Yellow background
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "20px",
  },
  logo: {
    width: "80px",
    marginBottom: "10px",
  },
  title: {
    fontSize: "28px",
    fontWeight: "bold",
    marginBottom: "10px",
  },
  subtitle: {
    fontSize: "16px",
    textAlign: "center",
  },
  rightSection: {
    flex: 0.7, // 70% of the width
    backgroundColor: "#FFFFFF",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "20px",
  },
  form: {
    width: "80%",
    maxWidth: "400px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  formTitle: {
    fontSize: "24px",
    fontWeight: "bold",
    marginBottom: "10px",
  },
  description: {
    fontSize: "14px",
    textAlign: "center",
    marginBottom: "20px",
  },
  inputGroup: {
    width: "100%",
    marginBottom: "15px",
  },
  input: {
    width: "100%",
    padding: "10px",
    fontSize: "16px",
    borderRadius: "5px",
    border: "1px solid #ccc",
  },
  button: {
    width: "100%",
    padding: "10px",
    fontSize: "16px",
    backgroundColor: "#FFD700",
    color: "#000000",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
  },
  goBackText: {
    marginTop: "20px",
    fontSize: "14px",
    textAlign: "center",
  },
  link: {
    color: "#b6a005",
    textDecoration: "none",
  },
};

export default VerifyResetCode;
