import React from 'react';
import { useNavigate } from 'react-router-dom';

const Header = ({ title }) => {
      const navigate = useNavigate();

const navigateToHome = () => {
    navigate("/home");
 
};

return (
    <div className="dashboard-header">
        <div></div>
        <div>
            <h1>{title}</h1>
            <p>Welcome to your dashboard, manage everything here!</p>
        </div>
        <p className="user-switch" onClick={navigateToHome}>Switch account</p>
    </div>
);
};

export default Header;
