import React, { useEffect, useState } from "react";
import axios from "axios";
import "./SelectHobbyList.css";
import { BASE_URL } from "../../../api/core";
import { useNavigate } from "react-router-dom";
import Logo from "../../../assets/logo_home.png";
import { message } from "antd";

const SelectHobbyList = () => {
  const navigate = useNavigate();

  const [hobbies, setHobbies] = useState([]);
  const [filteredHobbies, setFilteredHobbies] = useState([]);
  const [selectedHobbies, setSelectedHobbies] = useState([]);
  const [isHobbiesLoading, setLoadHobbies] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 35; 

  useEffect(() => {
    const fetchHobbies = async () => {
      const token = localStorage.getItem("token");
      const hideLoading = message.loading("Fetching hobbies...", 0);

      setLoadHobbies(false);

      try {
        const response = await axios.get(`${BASE_URL}/hobbies/user/select`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const { data } = response;
        setHobbies(data.hobbies);
        setFilteredHobbies(data.hobbies);
      } catch (error) {
        console.error("Error fetching hobbies:", error);
      } finally {
        hideLoading();
        setLoadHobbies(true);
      }
    };

    fetchHobbies();
  }, []);

  const handleSelect = (hobby) => {
    const user = localStorage.getItem("user");
    const parsedUser = JSON.parse(user);

    setSelectedHobbies((prevSelectedHobbies) => {
      const existingHobby = prevSelectedHobbies.find(
        (selected) => selected.hobby_id === hobby.id
      );
      if (existingHobby) {
        return prevSelectedHobbies.filter(
          (selected) => selected.hobby_id !== hobby.id
        );
      } else {
        return [
          ...prevSelectedHobbies,
          { hobby_id: hobby.id, user_id: parsedUser?.id },
        ];
      }
    });
  };

  const handleProceed = () => {
    if (selectedHobbies.length > 0) {
      saveUserHobbies(selectedHobbies);
    } else {
      alert("Please select at least one hobby.");
    }
  };

  const saveUserHobbies = async (selectedHobbies) => {
    const token = localStorage.getItem("token");

    if (!token) return;

    const hideLoading = message.loading("Saving hobbies...", 0);

    try {
      await axios.post(
        `${BASE_URL}/hobbies/user`,
        { hobbies: selectedHobbies },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
          },
        }
      );
      navigate("/");
    } catch (error) {
      console.error("Error saving hobbies:", error);
    } finally {
      hideLoading();
    }
  };

  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchTerm(value);
    setFilteredHobbies(
      hobbies.filter((hobby) => hobby.name.toLowerCase().includes(value))
    );
    setCurrentPage(1); // Reset to the first page after filtering
  };

  const totalPages = Math.ceil(filteredHobbies.length / itemsPerPage);
  const currentItems = filteredHobbies.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePageChange = (page) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  return (
    <div style={styles.container}>
      {/* Left Section */}
      <div style={styles.leftSection}>
        <img src={Logo} alt="Hobbee Logo" style={styles.logo} />
        <h1 style={styles.title}>Hobbee</h1>
        <p style={styles.subtitle}>Pursue Your Passion</p>
      </div>

      {/* Right Section */}
      <div style={styles.rightSection}>
        {isHobbiesLoading && (
          <>
            <div style={styles.header}>
              <h2>Select Hobbies</h2>
              <p>
                Choose at least one hobby and join a vibrant community of
                members who share your passions!
              </p>
              <input
                type="text"
                placeholder="Search hobbies..."
                value={searchTerm}
                onChange={handleSearch}
                style={styles.searchBox}
              />
            </div>
            <div style={styles.listContainer}>
              {currentItems.map((hobby) => (
                <div
                  key={hobby.id}
                  className={`list-item ${
                    selectedHobbies.find(
                      (selected) => selected.hobby_id === hobby.id
                    )
                      ? "active"
                      : ""
                  }`}
                  onClick={() => handleSelect(hobby)}
                  style={{
                    ...styles.listItem,
                    backgroundColor: selectedHobbies.find(
                      (selected) => selected.hobby_id === hobby.id
                    )
                      ? "#FFD700"
                      : "#f4f4f4",
                  }}
                >
                  {hobby.name}
                </div>
              ))}
            </div>
            <div style={styles.pagination}>
              <button
                onClick={() => handlePageChange(currentPage - 1)}
                style={styles.pageButton}
                disabled={currentPage === 1}
              >
                Previous
              </button>
              <span style={styles.pageInfo}>
                Page {currentPage} of {totalPages}
              </span>
              <button
                onClick={() => handlePageChange(currentPage + 1)}
                style={styles.pageButton}
                disabled={currentPage === totalPages}
              >
                Next
              </button>
            </div>
            <button
              onClick={handleProceed}
              style={styles.proceedButton}
              disabled={selectedHobbies.length === 0}
            >
              Proceed
            </button>
          </>
        )}
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    flexDirection: "row",
    height: "100vh",
    fontFamily: "Arial, sans-serif",
  },
  leftSection: {
    flex: 0.3, // 30% of the width
    backgroundColor: "#FFD700", // Yellow background
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "20px",
  },
  logo: {
    width: "80px",
    marginBottom: "10px",
  },
  title: {
    fontSize: "28px",
    fontWeight: "bold",
    marginBottom: "10px",
  },
  subtitle: {
    fontSize: "16px",
    textAlign: "center",
  },
  rightSection: {
    flex: 0.7, // 70% of the width
    backgroundColor: "#FFFFFF",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: "20px",
  },
  header: {
    textAlign: "center",
    marginBottom: "20px",
  },
  searchBox: {
    width: "100%",
    maxWidth: "400px",
    padding: "10px",
    fontSize: "16px",
    borderRadius: "5px",
    border: "1px solid #ccc",
    marginBottom: "20px",
  },
  listContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(5, 1fr)", // 5 columns per row
    gap: "10px",
    width: "100%",
  },
  listItem: {
    padding: "10px",
    borderRadius: "5px",
    cursor: "pointer",
    textAlign: "center",
    transition: "background-color 0.3s",
  },
  pagination: {
    marginTop: "20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },
  pageButton: {
    padding: "10px",
    fontSize: "14px",
    backgroundColor: "#FFD700",
    color: "#000000",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
  },
  pageInfo: {
    fontSize: "14px",
  },
  proceedButton: {
    marginTop: "20px",
    padding: "10px",
    fontSize: "16px",
    backgroundColor: "#FFD700",
    color: "#000000",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
    disabled: {
      backgroundColor: "#ccc",
      cursor: "not-allowed",
    },
  },
};

export default SelectHobbyList;
