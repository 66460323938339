import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { BASE_URL } from "../../../api/core";
import { message } from "antd";
import { UserContext } from "../../../context";
import Logo from "../../../assets/logo_home.png"; // Replace with your logo path
// import "./SendEmailLink.css"; // Add custom styles for better design

function SendEmailLink() {
  const { email, setEmail } = useContext(UserContext);
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();

  const handleContinue = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem("token");

    const hideLoading = message.loading("Submitting request...", 0);

    try {
      await axios.post(
        `${BASE_URL}/password/email`,
        { email: email },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setEmail(email);
      hideLoading();
      message.success("Reset link has been sent to your email.");
      navigate("/verify-reset-code");
    } catch (error) {
      hideLoading();
      const errorMessage =
        error.response?.data?.message || "An unknown error occurred.";
      messageApi.error(errorMessage);
    }
  };

  return (
    <div style={styles.container}>
      {contextHolder}
      {/* Left Section */}
      <div style={styles.leftSection}>
        <img src={Logo} alt="Hobbee Logo" style={styles.logo} />
        <h1 style={styles.title}>Hobbee</h1>
        <p style={styles.subtitle}>
          Forgot your password? Don’t worry, we’ll send you a link to reset it.
        </p>
      </div>

      {/* Right Section */}
      <div style={styles.rightSection}>
        <form onSubmit={handleContinue} style={styles.form}>
          <h2 style={styles.header}>Forgot Your Password?</h2>
          <p style={styles.description}>
            Enter your email address and we’ll send you a verification code to
            reset your password.
          </p>
          <div style={styles.inputGroup}>
            <input
              type="email"
              placeholder="Enter your email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              style={styles.input}
              required
            />
          </div>
          <button type="submit" style={styles.button}>
            Send Verification Code
          </button>
        </form>
        <p style={styles.footer}>
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              navigate("/");
            }}
            style={styles.backLink}
          >
            Go Back to Login
          </a>
        </p>
      </div>
    </div>
  );
}

const styles = {
  container: {
    display: "flex",
    flexDirection: "row",
    height: "100vh",
    fontFamily: "Arial, sans-serif",
  },
  leftSection: {
    flex: 0.4, // 40% of the width
    backgroundColor: "#FFD700", // Yellow background
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "20px",
    textAlign: "center",
  },
  logo: {
    width: "80px",
    marginBottom: "20px",
  },
  title: {
    fontSize: "28px",
    fontWeight: "bold",
    marginBottom: "10px",
  },
  subtitle: {
    fontSize: "16px",
    lineHeight: "1.5",
  },
  rightSection: {
    flex: 0.6, // 60% of the width
    backgroundColor: "#FFFFFF",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "20px",
  },
  form: {
    width: "100%",
    maxWidth: "400px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  header: {
    fontSize: "24px",
    fontWeight: "bold",
    marginBottom: "10px",
    textAlign: "center",
  },
  description: {
    fontSize: "14px",
    marginBottom: "20px",
    textAlign: "center",
    color: "#888888",
  },
  inputGroup: {
    width: "100%",
    marginBottom: "20px",
  },
  input: {
    width: "100%",
    padding: "10px",
    fontSize: "16px",
    borderRadius: "5px",
    border: "1px solid #ccc",
  },
  button: {
    padding: "10px",
    fontSize: "16px",
    backgroundColor: "#FFD700",
    color: "#000000",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
    width: "100%",
  },
  footer: {
    marginTop: "20px",
    fontSize: "14px",
  },
  backLink: {
    color: "#b6a005",
    textDecoration: "none",
  },
};

export default SendEmailLink;
