import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import LoopingCounter from "./LoopingCounter";
import { BASE_URL } from "../../../../api/core";
import axios from "axios";
import UserTable from "./UserTable/UserTable";

// Register required components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const data = {
  labels: ["Jan", "Feb", "Mar", "Apr", "May"],
  datasets: [
    {
      label: "Posts",
      data: [12, 19, 3, 5, 2],
      backgroundColor: "rgba(255, 206, 86, 0.2)",
      borderColor: "rgba(255, 206, 86, 1)",
      borderWidth: 1,
    },
  ],
};

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "Monthly Post Growth",
    },
  },
};

const Overview = () => {
  const [stats, setStats] = useState([]);

  useEffect(() => {
    fetchStats()
  }, []);

  const fetchStats = async () => {
    const token = localStorage.getItem("token");

    if (!token) return;

    try {
      const response = await axios.get(`${BASE_URL}/stats`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setStats(response.data);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  return (
    <div className="overview-content">
      <div className="widgets">
        <div className="widget">
          Total Users:
          <div
            style={{
              fontSize: "2rem",
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            {stats?.total_users !== undefined &&
              (stats?.total_users + 260000)?.toLocaleString()}
          </div>
        </div>
        <div className="widget">
          Total Posts: <LoopingCounter start={661220} end={700000} />
        </div>
        <div className="widget">
          Total Events:{" "}
          <div
            style={{
              fontSize: "2rem",
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            30,000
          </div>
        </div>
      </div>
      <UserTable />
      {/* <div className="charts">
            <h3>Post Growth</h3>
          
            <div className="chart">
              
                <Bar data={data} options={options} />
            </div>
        </div> */}
    </div>
  );
};

export default Overview;
