import React, { useContext, useEffect, useState } from "react";
import "./App.css";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";

import Home from "./pages/home/Home";
import Login from "./pages/Auth/Login/Login";
import Registration from "./pages/Auth/Registration/Registration";
import VerifyUser from "./pages/Auth/VerifyUser/VerifyUser";
import { UserProvider } from "./context";
import UserProfile from "./components/profile/UserProfile";
import AddHobbies from "./pages/admin/AddHobbies";
import UploadHobbieIcons from "./pages/admin/UploadHobbieIcons";
import UserPolicy from "./pages/privacy/UserPolicy/UserPolicy";
import AddAvatar from "./pages/Auth/AddAvatar/AddAvatar";
import AddDetails from "./pages/Auth/AddDetails/AddDetails";
import UserDeletion from "./pages/privacy/UserDeletion/UserDeletion";
import SelectHobbyList from "./pages/Auth/SelectHobby/SelectHobbyList";
import UserSupport from "./pages/support/UserSupport";
import SendEmailLink from "./pages/Auth/forgotpassword/SendEmailLink";
import VerifyResetCode from "./pages/Auth/forgotpassword/VerifyResetCode";
import ResetPassword from "./pages/Auth/forgotpassword/ResetPassword";
import Dashboard from "./pages/admin/Dashboard/Dashboard";

function App() {
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    // Define event handlers for online and offline events
    const handleOnline = () => setIsOnline(true);
    const handleOffline = () => setIsOnline(false);

    // Attach event listeners
    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    // Cleanup event listeners on component unmount
    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []); // Run once on component mount

  return (
    <div className="app">
      {isOnline ? null : (
        <div style={{ background: "white" }}>
          <p style={{ color: "red" }}>You are offline!</p>
        </div>
      )}

      <UserProvider>
        <Routes>
          <Route path="/" element={<Login />}></Route>
          <Route path="send-email" element={<SendEmailLink />}></Route>
          <Route path="verify-reset-code" element={<VerifyResetCode />}></Route>
          <Route path="reset-password" element={<ResetPassword />}></Route>
          {/* <Route path="reset-password" element={<ResetPassword />}></Route> */}
          <Route path="registration" element={<Registration />}></Route>
          <Route path="add-details" element={<AddDetails />}></Route>
          <Route path="add-avatar" element={<AddAvatar />}></Route>
          <Route path="select-hobbies" element={<SelectHobbyList />}></Route>
          <Route path="verify-user" element={<VerifyUser />}></Route>
          <Route path="home" element={<Home />}></Route>
          <Route path="profile" element={<UserProfile />}></Route>
          <Route path="addHobby" element={<AddHobbies />}></Route>
          <Route path="dashboard" element={<Dashboard />}></Route>
          <Route path="AddIcons" element={<UploadHobbieIcons />}></Route>
          <Route path="privacy-policies" element={<UserPolicy />}></Route>
          <Route path="delete-account" element={<UserDeletion />}></Route>
          <Route path="user-support" element={<UserSupport />}></Route>
        </Routes>
      </UserProvider>
    </div>
  );
}

export default App;
