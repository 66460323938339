import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { BASE_URL } from "../../../api/core";
import Logo from "../../../assets/logo_home.png"; // Replace with the correct path
import { message } from "antd";
import PlacesAutocomplete from "../../../components/common/PlacesAutocomplete/PlacesAutocomplete";
// import "./AddDetails.css";

const AddDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const userId = location.state?.userId;

  const [selectedPlace, setSelectedPlace] = useState(null);
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    username: "",
    firstname: "",
    lastname: "",
    bio: "",
  });

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/");
    } else {
      navigate("/add-details");
    }
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validate = () => {
    let errors = {};

    if (!formData.username.trim()) {
      errors.username = "Username is required";
    }
    if (!formData.firstname.trim()) {
      errors.firstname = "First name is required";
    }
    if (!formData.lastname.trim()) {
      errors.lastname = "Last name is required";
    }
    if (!selectedPlace?.location?.lat()) {
      errors.location = "Location is required";
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      const profileData = {
        firstname: formData.firstname,
        lastname: formData.lastname,
        username: formData.username,
        bio: formData.bio,
        latitude: selectedPlace.location.lat(),
        longitude: selectedPlace.location.lng(),
      };

      const token = localStorage.getItem("token");
      if (!token) return;

      const hideLoading = message.loading("Saving details...", 0);

      try {
        await axios.put(`${BASE_URL}/add-details`, profileData, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        navigate("/add-avatar", { state: { userId } });
      } catch (error) {
        console.error("Error updating profile:", error);
      } finally {
        hideLoading();
      }
    }
  };

  return (
    <div style={styles.container}>
      {/* Left Section */}
      <div style={styles.leftSection}>
        <img src={Logo} alt="Hobbee Logo" style={styles.logo} />
        <h1 style={styles.title}>Hobbee</h1>
        <p style={styles.subtitle}>Pursue Your Passion</p>
      </div>

      {/* Right Section */}
      <div style={styles.rightSection}>
        <form onSubmit={handleSubmit} style={styles.form}>
          <h2 style={styles.formTitle}>Add Your Details</h2>
          <div style={styles.inputGroup}>
            <input
              type="text"
              placeholder="Username"
              name="username"
              value={formData.username}
              onChange={handleChange}
              style={styles.input}
            />
            {errors.username && <span style={styles.error}>{errors.username}</span>}
          </div>
          <div style={styles.inputGroup}>
            <input
              type="text"
              placeholder="First Name"
              name="firstname"
              value={formData.firstname}
              onChange={handleChange}
              style={styles.input}
            />
            {errors.firstname && (
              <span style={styles.error}>{errors.firstname}</span>
            )}
          </div>
          <div style={styles.inputGroup}>
            <input
              type="text"
              placeholder="Last Name"
              name="lastname"
              value={formData.lastname}
              onChange={handleChange}
              style={styles.input}
            />
            {errors.lastname && (
              <span style={styles.error}>{errors.lastname}</span>
            )}
          </div>
          <div style={styles.inputGroup}>
            <PlacesAutocomplete setSelectedPlace={setSelectedPlace} />
            {errors.location && <span style={styles.error}>{errors.location}</span>}
          </div>
          <button type="submit" style={styles.button}>
            Save
          </button>
        </form>
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    flexDirection: "row",
    height: "100vh",
    fontFamily: "Arial, sans-serif",
  },
  leftSection: {
    flex: 1,
    backgroundColor: "#FFD700", // Yellow background
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "20px",
  },
  logo: {
    width: "80px",
    marginBottom: "10px",
  },
  title: {
    fontSize: "28px",
    fontWeight: "bold",
    marginBottom: "10px",
  },
  subtitle: {
    fontSize: "16px",
    textAlign: "center",
  },
  rightSection: {
    flex: 1,
    backgroundColor: "#FFFFFF",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "20px",
  },
  form: {
    width: "80%",
    maxWidth: "400px",
    display: "flex",
    flexDirection: "column",
  },
  formTitle: {
    fontSize: "24px",
    fontWeight: "bold",
    marginBottom: "20px",
    textAlign: "center",
  },
  inputGroup: {
    marginBottom: "15px",

  },
  input: {
    width: "100%",
    padding: "10px",
    fontSize: "16px",
    border: "1px solid #ccc",
    borderRadius: "5px",
  },
  button: {
    padding: "10px",
    fontSize: "16px",
    backgroundColor: "#FFD700",
    color: "#000000",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
  },
  error: {
    fontSize: "12px",
    color: "red",
  },
};

export default AddDetails;
