import React, { useState, useEffect } from "react";

const LoopingCounter = ({start, end}) => {

  const INTERVAL = 10000; // Interval in milliseconds (adjust for speed)

  const [counter, setCounter] = useState(start);

  useEffect(() => {
    const interval = setInterval(() => {
      setCounter((prevCounter) => {
        if (prevCounter >= end) {
          return start; // Restart the counter
        }
        return prevCounter + 1; // Increment counter
      });
    }, INTERVAL);

    return () => clearInterval(interval); // Clean up interval on component unmount
  }, []);

  return (
    <div style={{ fontSize: "2rem", fontWeight: "bold", textAlign: "center" }}>
      {counter.toLocaleString()} {/* Format the number with commas */}
    </div>
  );
};

export default LoopingCounter;
