import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { message } from "antd";
import Logo from "../../../assets/logo_home.png"; // Replace with the correct path
import { BASE_URL } from "../../../api/core";
import "./Registration.css"; // Ensure CSS is properly defined

const Registration = () => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    retype_password: "",
    latitude: 0.0,
    longitude: 0.0,
  });
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();

  const errorMsg = (msg) => {
    messageApi.open({
      type: "error",
      content: msg,
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validate = () => {
    let errors = {};
    if (!formData.email) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = "Email address is invalid";
    }
    if (!formData.password) {
      errors.password = "Password is required";
    } else if (formData.password.length < 6) {
      errors.password = "Password must be at least 6 characters";
    }
    if (formData.password !== formData.retype_password) {
      errors.retype_password = "Passwords do not match";
    }
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      const hideLoading = message.loading("Registration in progress...", 0);
      try {
        const response = await axios.post(`${BASE_URL}/register`, formData);
        if (response) {
          const { token, user } = response.data;
          localStorage.setItem("user", JSON.stringify(user));
          localStorage.setItem("token", token);
          navigate("/add-details");
        }
      } catch (error) {
        if (error.response) {
          const errorString = Object.keys(error.response.data.error)
            .map((key) => error.response.data.error[key])
            .join("\n");
          errorMsg(errorString);
        } else {
          alert("Registration failed. Please try again later.");
        }
      } finally {
        hideLoading();
      }
    }
  };

  return (
    <div style={styles.container}>
      {contextHolder}
      {/* Left Section */}
      <div style={styles.leftSection}>
        <img src={Logo} alt="Hobbee Logo" style={styles.logo} />
        <h1 style={styles.title}>Hobbee</h1>
        <p style={styles.subtitle}>Pursue Your Passion</p>
      </div>

      {/* Right Section */}
      <div style={styles.rightSection}>
        <form onSubmit={handleSubmit} style={styles.form}>
          <h2 style={styles.formTitle}>Create an Account</h2>
          <div style={styles.inputGroup}>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="Email"
              style={styles.input}
            />
            {errors.email && <span style={{ ...styles.errorText, ...styles.centerError }}>{errors.email}</span>}
          </div>
          <div style={styles.inputGroup}>
            <input
              type="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              placeholder="New Password"
              style={styles.input}
            />
            {errors.password && (
              <span style={{ ...styles.errorText, ...styles.centerError }}>{errors.password}</span>
            )}
          </div>
          <div style={styles.inputGroup}>
            <input
              type="password"
              name="retype_password"
              value={formData.retype_password}
              onChange={handleChange}
              placeholder="Retype Password"
              style={styles.input}
            />
            {errors.retype_password && (
              <span style={{ ...styles.errorText, ...styles.centerError }}>{errors.retype_password}</span>
            )}
          </div>
          <button type="submit" style={styles.button}>
            Sign Up
          </button>
          <p style={styles.termsText}>
            By clicking Sign Up, you agree to our{" "}
            <a
              href="/privacy-policies"
              onClick={(e) => {
                e.preventDefault();
                navigate("/privacy-policies");
              }}
              style={styles.link}
            >
              Terms of Use or Data Policy
            </a>
            .
          </p>
        </form>
        <p style={styles.switchText}>
          Already have an account?{" "}
          <a
            href="/"
            onClick={(e) => {
              e.preventDefault();
              navigate("/");
            }}
            style={styles.link}
          >
            Log In
          </a>
        </p>
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    flexDirection: "row",
    height: "100vh",
    fontFamily: "Arial, sans-serif",
  },
  leftSection: {
    flex: 1,
    backgroundColor: "#FFD700", // Yellow background
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "20px",
  },
  logo: {
    width: "80px",
    marginBottom: "10px",
  },
  title: {
    fontSize: "28px",
    fontWeight: "bold",
    marginBottom: "10px",
  },
  subtitle: {
    fontSize: "16px",
    textAlign: "center",
  },
  rightSection: {
    flex: 1,
    backgroundColor: "#FFFFFF",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "20px",
  },
  form: {
    width: "80%",
    maxWidth: "400px",
    display: "flex",
    flexDirection: "column",
  },
  formTitle: {
    fontSize: "24px",
    fontWeight: "bold",
    marginBottom: "20px",
    textAlign: "center",
  },
  inputGroup: {
    marginBottom: "15px",
  },
  input: {
    width: "100%",
    padding: "10px",
    fontSize: "16px",
    border: "1px solid #ccc",
    borderRadius: "5px",
  },
  button: {
    padding: "10px",
    fontSize: "16px",
    backgroundColor: "#FFD700",
    color: "#000000",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
  },

  errorText: {
    fontSize: "12px",
    color: "red",
    marginTop: "-18px",
    height: 0,
  },
  centerEerror: {
    width: "100%",
    textAlign: "center",
  },
  termsText: {
    fontSize: "12px",
    marginTop: "10px",
    textAlign: "center",
  },
  link: {
    color: "#b6a005",
    textDecoration: "none",
  },
  switchText: {
    fontSize: "14px",
    marginTop: "20px",
    textAlign: "center",
  },
};

export default Registration;
