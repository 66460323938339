import React, { useState } from "react";
import "./Dashboard.css"; // Import a CSS file for additional styles
import Logo from "../../../assets/logo_home.png"; // Replace with the correct path
import Overview from "./components/Overview";
import Header from "./components/Header";
import { Position } from "@react-pdf-viewer/core";

const Dashboard = () => {
  const [activeTab, setActiveTab] = useState("Overview");

  const tabs = ["Overview", "Hobbies", "Profile", "Settings", "Logout"];

  const renderContent = () => {
    switch (activeTab) {
      case "Overview":
        return <Overview/>;
      case "Hobbies":
        return <Hobbies />;
      case "Profile":
        return <Profile />;
      case "Settings":
        return <Settings />;
      case "Logout":
        return <Logout />;
      default:
        return <Overview />;
    }
  };

  return (
    <div style={styles.dashboardContainer}>
      {/* Sidebar */}
      <div style={styles.sidebar}>
        <div style={styles.logoContainer}>
          <img src={Logo} alt="Hobbee Logo" style={styles.logo} />
          <h2 style={styles.logoText}>Hobbee</h2>
        </div>
        <ul style={styles.navList}>
          {tabs.map((tab) => (
            <li
              key={tab}
              onClick={() => setActiveTab(tab)}
              style={{
                ...styles.navItem,
                backgroundColor: activeTab === tab ? "#FFD700" : "transparent",
              }}
            >
              {tab}
            </li>
          ))}
        </ul>
      </div>

      {/* Main Content */}
      <div style={styles.mainContent}>
        <div style={styles.header}>
            <Header title={activeTab}/>
    
        </div>
        <div style={styles.content}>{renderContent()}</div>
      </div>
    </div>
  );
};

// Dummy Components for Content
// const Overview = () => <p>This is the Overview section.</p>;
const Hobbies = () => <p>This is the Hobbies section.</p>;
const Profile = () => <p>This is the Profile section.</p>;
const Settings = () => <p>This is the Settings section.</p>;
const Logout = () => <p>You have been logged out!</p>;

const styles = {
  dashboardContainer: {
    display: "flex",
    // height: "100vh",
    fontFamily: "Arial, sans-serif",
  },
  sidebar: {
    position: "fixed", 
    width: "250px",
    flex: 0.2, // 20% of the width
    backgroundColor: "#FFD700", // Yellow background
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
    height: "100%",
  },
  logoContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: "20px",
  },
  logo: {
    width: "60px",
    marginBottom: "10px",
  },
  logoText: {
    fontSize: "20px",
    fontWeight: "bold",
  },
  navList: {
    listStyleType: "none",
    padding: 0,
    width: "100%",
  },
  navItem: {
    padding: "10px",
    textAlign: "center",
    fontSize: "16px",
    cursor: "pointer",
    transition: "background-color 0.3s",
    borderRadius: "5px",
    marginBottom: "10px",
  },
  mainContent: {
    marginLeft: "370px",
    // flex: 0.8, // 80% of the width
    backgroundColor: "#FFFFFF",
    display: "flex",
    flexDirection: "column",
    padding: "20px",
  },
  header: {
    marginBottom: "20px",
    borderBottom: "1px solid #ccc",
    paddingBottom: "10px",
  },
  content: {
    flex: 1,
    backgroundColor: "#f9f9f9",
    borderRadius: "5px",
    padding: "20px",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
  },
};

export default Dashboard;
